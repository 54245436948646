// Session
const IP_API_KEY = process.env.VUE_APP_IP_API_KEY;
export const LOGIN = { method: 'post', endpoint: 'login' };
export const LOGOUT = { method: 'post', endpoint: 'logout' };
export const FETCH_USER = { method: 'get', endpoint: (queryParams = '') => `fetch_user${queryParams}` };
export const CHECK_EMERGENCY_MODE = { method: 'get', endpoint: 'emergency_mode/status' };
// Media Library
export const GET_MEDIA = { method: 'get', endpoint: 'media' };
export const GET_PUSH_NOTIFICATIONS_MEDIA = { method: 'get', endpoint: (queryParams) => `media${queryParams}` };
export const CREATE_MEDIA = { method: 'post', endpoint: 'media' };
export const GET_RESOURCE = { method: 'get', endpoint: (mediaId) => `media/${mediaId}` };
export const UPDATE_MEDIA = { method: 'post', endpoint: (mediaId) => `media/${mediaId}` };
export const DELETE_MEDIA = { method: 'put', endpoint: 'media/delete' };
export const GET_MEDIA_CATEGORIES = { method: 'get', endpoint: 'media/categories' };
export const GET_MEDIA_EXTRA_FIELDS = { method: 'get', endpoint: 'media/extra_fields_countries' };

// Banners
export const LIST_BANNERS = { method: 'get', endpoint: (userId, queryParams) => `users/${userId}/banners/list${queryParams}` };
export const GET_BANNER = { method: 'get', endpoint: (bannerId) => `banners/${bannerId}` };
export const GET_BANNERS = { method: 'get', endpoint: 'banners' };
export const CREATE_BANNER = { method: 'post', endpoint: 'banners' };
export const UPDATE_BANNER = { method: 'post', endpoint: (bannerId) => `banners/${bannerId}` };
export const REMOVE_BANNER = { method: 'put', endpoint: (bannerId) => `banners/${bannerId}/delete` };

// Highlight Videos
export const GET_HIGHLIGHT_VIDEO = { method: 'get', endpoint: (highlightVideoId) => `highlight_videos/${highlightVideoId}` };
export const GET_HIGHLIGHT_VIDEOS = { method: 'get', endpoint: 'highlight_videos' };
export const GET_COUNTRIES_EXTRA_LINKS = { method: 'get', endpoint: 'highlight_videos/extra_urls_countries' };
export const GET_PUSH_NOTIFICATIONS_HIGHLIGHT_VIDEOS = { method: 'get', endpoint: (queryParams) => `highlight_videos${queryParams}` };
export const GET_HIGHLIGHT_VIDEO_CATEGORIES = { method: 'get', endpoint: 'videos/categories/all' };
export const CREATE_HIGHLIGHT_VIDEO = { method: 'post', endpoint: 'highlight_videos' };
export const UPDATE_HIGHLIGHT_VIDEO = { method: 'post', endpoint: (highlightVideoId) => `highlight_videos/${highlightVideoId}` };
export const REMOVE_HIGHLIGHT_VIDEO = { method: 'put', endpoint: (highlightVideoId) => `highlight_videos/${highlightVideoId}/delete` };
export const SORT_HIGHLIGHT_VIDEO = { method: 'post', endpoint: (highlightVideoId) => `highlight_videos/${highlightVideoId}/sort` };

// Reports
export const CASH_PAYMENTS = { method: 'get', endpoint: 'cash_payments' };
export const ORDER_VERIFICATIONS = { method: 'get', endpoint: (type) => `order_verifications/${type}` };
export const DOWNLOAD_ORDER_VERIFICATIONS = { method: 'get', endpoint: 'order_verifications/download' };
export const NEXT_PURCHASE_HISTORY = { method: 'get', endpoint: (userId) => `reports/${userId}/next_purchase_history` };
export const AUTOSHIP_SUMMARY = { method: 'get', endpoint: (userId) => `reports/${userId}/autoship_summary` };
export const ACTIVATION_HISTORY = { method: 'get', endpoint: 'reports/activation_history' };
export const COMMISSIONS = { method: 'get', endpoint: 'reports/commissions' };
export const BV = { method: 'get', endpoint: (userId) => `reports/${userId}/bv` };
export const PENDING_BV = { method: 'get', endpoint: (userId) => `reports/${userId}/pending_bv` };
export const PROCESS_PAYMENTS = { method: 'put', endpoint: 'cash_payments' };
export const USER_NOTES = { method: 'get', endpoint: 'reports/users/notes' };
export const PBV = { method: 'get', endpoint: (userId) => `reports/${userId}/pbv` };
export const DISTRIBUTOR_ORGANIZATION = { method: 'get', endpoint: (queryParams) => `reports/distributor_organization${queryParams}` };
export const TOP_PBV = { method: 'get', endpoint: (userId) => `reports/${userId}/pbv/top` };
export const NEW_ENROLLMENT = { method: 'get', endpoint: (userId) => `reports/${userId}/new_enrollment` };
export const NEW_ENROLLED_USERS = { method: 'get', endpoint: (userId, queryParams) => `reports/${userId}/new_enrollment/users${queryParams}` };
export const TOP_NEW_ENROLLMENT = { method: 'get', endpoint: (userId) => `reports/${userId}/new_enrollment/top` };
export const DOWNLOAD_TOP_NEW_ENROLLMENT = { method: 'get', endpoint: (userId) => `reports/${userId}/new_enrollment/top/download` };
export const GET_UNILEVEL_RANKS = { method: 'get', endpoint: (userId) => `reports/${userId}/unilevel_ranks` };
export const GET_RISING_STARS = { method: 'get', endpoint: 'reports/rising_stars' };
export const DOWNLOAD_RISING_STARS_REPORT = { method: 'get', endpoint: 'reports/rising_stars/download' };
export const GET_RANK_HISTORIES = { method: 'get', endpoint: 'reports/rank_histories' };
export const GET_SETTLEMENT_EXPENSES = { method: 'get', endpoint: 'reports/settlement/expenses' };
export const DOWNLOAD_RANK_HISTORIES_REPORT = { method: 'get', endpoint: 'reports/rank_histories/download' };
// export const GET_ANALYTICS = { method: 'get', endpoint: (userId, queryParams) => `reports/${userId}/replicated_site/analytics${queryParams}` };
// export const GET_REALTIME_ANALYTICS = { method: 'get', endpoint: (userId) => `reports/${userId}/replicated_site/realtime_analytics` };
export const GET_VIDEOS = { method: 'get', endpoint: (userId) => `reports/${userId}/orders_videos` };
export const ALL_FRAUD_ORDERS = { method: 'get', endpoint: 'reports/fraud_orders' };
export const DOWNLOAD_FRAUD_ORDERS = { method: 'get', endpoint: 'reports/fraud_orders/download' };
export const GET_CUSTOMER_LIFETIME = { method: 'get', endpoint: (type, queryParams) => `reports/customer_lifetime/${type}${queryParams}` };
export const TOTAL_EARNINGS = { method: 'get', endpoint: 'reports/total_earnings' };
export const TOTAL_EARNINGS_DETAILS = { method: 'get', endpoint: 'reports/total_earnings_detail' };
export const DOWNLOAD_TOTAL_EARNINGS_REPORT = { method: 'get', endpoint: 'reports/total_earnings/download' };
export const GET_TRIP_POINTS_REPORT = { method: 'get', endpoint: 'reports/trip_points' };
export const DOWNLOAD_TRIP_POINTS_REPORT = { method: 'get', endpoint: 'reports/trip_points/download' };
export const GET_TRIP_POINTS_HISTORY = { method: 'get', endpoint: (userId) => `reports/${userId}/trip_points_history` };

// Reports Taxes
export const ALL_TAXES = { method: 'get', endpoint: 'reports/taxes' };
export const SUMMARY_TAXES = { method: 'get', endpoint: 'reports/taxes/summary' };
export const DOWNLOAD_TAX_REPORT = { method: 'get', endpoint: 'reports/taxes/download' };

// Reports Sales
export const ALL_SALES = { method: 'get', endpoint: 'reports/sales' };
export const SUMMARY_SALES = { method: 'get', endpoint: 'reports/sales/summary' };
export const STATE_SALES = { method: 'get', endpoint: 'reports/sales/state' };
export const COUNTRY_SALES = { method: 'get', endpoint: 'reports/sales/country' };
export const CONTINENT_SALES = { method: 'get', endpoint: 'reports/sales/continent' };
export const PRODUCT_SALES = { method: 'get', endpoint: 'reports/sales/product' };
export const PERIOD_SALES = { method: 'get', endpoint: 'reports/sales/period' };
export const PAYMENT_METHOD_SALES = { method: 'get', endpoint: 'reports/sales/payment_method' };
export const COMPARISON_SALES = { method: 'get', endpoint: 'reports/sales/comparison' };
export const GET_DISTRIBUTOR_VOLUME = { method: 'get', endpoint: 'reports/sales/volume' };
export const ALL_REFUNDS = { method: 'get', endpoint: 'reports/refunds' };
export const DOWNLOAD_SALES_REPORT = { method: 'get', endpoint: 'reports/sales/download' };

// Reports Sales Details
export const ORDERS_INFORMATION = { method: 'get', endpoint: 'reports/sales_details/orders_information' };
export const ORDERS_PRODUCT_INFORMATION = { method: 'get', endpoint: 'reports/sales_details/orders_product_information' };
export const EXPORT_SALES_DETAILS_REPORT = { method: 'get', endpoint: 'reports/sales_details/export' };

// Agency Sales Details
export const AGENCY_SALES_REPORT = { method: 'get', endpoint: 'reports/sales_details/all_sales' };
export const DOWNLOAD_AGENCY_SALES_REPORT = { method: 'get', endpoint: 'reports/sales_details/all_sales/download' };

// Agency endpoints
export const GET_AGENCY_ACTIVE_PROMO = { method: 'get', endpoint: (queryParams) => `replicated_site/agency/promotions${queryParams}` };
export const GET_AGENCY_REPLICATED_INFO = { method: 'get', endpoint: 'replicated_site/agency/links' };

export const GET_AGENCY_PROMO_TYPES = { method: 'get', endpoint: 'agency_promotion/types' };
export const GET_AGENCY_PROMOTIONS = { method: 'get', endpoint: 'agency_promotions' };
export const SCHEDULE_AGENCY_PROMOTION = { method: 'post', endpoint: 'agency_promotions' };
export const DISABLE_AGENCY_PROMOTION = { method: 'delete', endpoint: (promoId) => `agency_promotions/${promoId}` };
export const SAVE_PROMO_EMAIL = { method: 'post', endpoint: 'replicated_site/agency/save_promo_email' };

// Orders
export const INVOICE = { method: 'get', endpoint: (orderId) => `orders/${orderId}/invoice` };
export const ORDERS = { method: 'get', endpoint: 'orders' };
export const PICKUP_ORDERS = { method: 'get', endpoint: 'orders/pickup' };
export const MANUAL_ORDERS = { method: 'get', endpoint: 'orders/manual_orders' };
export const MANUAL_ORDERS_BY_TYPE = { method: 'get', endpoint: 'orders/manual_orders/types' };
export const REFERRED_ORDERS = { method: 'get', endpoint: (userId) => `users/${userId}/referred_orders` };
export const BACKORDERS = { method: 'get', endpoint: 'orders/backorders' };
export const GET_ORDERS_DETAILS = { method: 'get', endpoint: (orderId) => `orders/${orderId}` };
export const UPDATE_ORDER_SHIPPING_ADDRESS = { method: 'put', endpoint: (orderId) => `orders/${orderId}/shipping_address` };
export const GET_ORDER_DOCUMENT = { method: 'get', endpoint: (orderId, side) => `orders/${orderId}/document?side=${side}`, module: 'Addresses' };
export const GET_ORDER_INFO = { method: 'get', endpoint: (orderId) => `orders/${orderId}/info` };
export const GET_ORDER_REFUNDS = { method: 'get', endpoint: (orderId) => `orders/${orderId}/refunds` };
export const GET_ORDER_MULTI_PAYMENTS = { method: 'get', endpoint: (userId) => `orders/multi_payments?user_id=${userId}` };
export const UPLOAD_SETTLEMENT_FILE = { method: 'post', endpoint: 'orders/upload_settlement' };
export const GET_SETTLEMENTS = { method: 'get', endpoint: 'orders/settlements' };
export const GET_SETTLEMENT_DETAIL = { method: 'get', endpoint: (settlementId) => `orders/settlements/${settlementId}` };
export const APPROVE_SETTLEMENT = { method: 'post', endpoint: (settlementId) => `orders/settlements/${settlementId}/approve` };
export const REJECT_SETTLEMENT = { method: 'post', endpoint: (settlementId) => `orders/settlements/${settlementId}/reject` };
export const GET_REFUND_REASONS = { method: 'get', endpoint: 'refund/reasons' };
export const GET_ORDER_REFUND_AMOUNT = { method: 'post', endpoint: (orderId) => `orders/${orderId}/refundable_amount` };
export const CHANGE_ORDER_PAYMENT_METHOD = { method: 'patch', endpoint: (orderId) => `orders/${orderId}/payment` };
export const PICKUP_CHANGE_ORDER_PAYMENT_METHOD = { method: 'patch', endpoint: (orderId) => `pickup/orders/${orderId}/payment` };
export const REFUND_ORDER = { method: 'put', endpoint: (orderId) => `orders/${orderId}/refund` };
export const DOWNLOAD_REFUND = { method: 'get', endpoint: (refundId) => `orders/${refundId}/download_refund` };
export const GET_DOWNLOADS = { method: 'get', endpoint: (userId) => `reports/${userId}/downloads` };
export const DOWNLOAD_PRODUCT = { method: 'get', endpoint: (itemId) => `downloads/${itemId}/download` };
export const MANUAL_ORDER = { method: 'post', endpoint: 'manual_order' };
export const COMPLETE_ORDER = { method: 'patch', endpoint: (orderId) => `orders/${orderId}/complete` };
export const FRAUD_ORDER = { method: 'post', endpoint: (orderId) => `orders/${orderId}/fraud` };
export const APPROVE_ORDER_VERIFICATION = { method: 'post', endpoint: (orderId) => `orders/${orderId}/approve` };
export const APPROVE_ORDER_SHIPPING_VERIFICATION = { method: 'post', endpoint: (orderId) => `orders/${orderId}/approve_shipping` };
export const REJECT_ORDER_VERIFICATION = { method: 'post', endpoint: (orderId) => `orders/${orderId}/reject` };
export const REJECT_ORDER_SHIPPING_VERIFICATION = { method: 'post', endpoint: (orderId) => `orders/${orderId}/reject_shipping` };
export const REQUEST_DOCUMENT_UPDATE_VERIFICATION = { method: 'post', endpoint: (orderId) => `orders/${orderId}/shipping_update_notification` };
export const UPDATE_ORDER_DOCUMENTS = { method: 'post', endpoint: (orderId) => `orders/${orderId}/updateDocument` };
export const PENDING_ORDERS_DOCUMENT_ALERT = { method: 'get', endpoint: 'orders_verifications/alerts' };

// Users
export const USERS = { method: 'get', endpoint: 'distributors' };
export const USER_DATA = { method: 'get', endpoint: (userID) => `users/${userID}` };
export const NEW_USER = { method: 'post', endpoint: 'users' };
export const NEW_DISTRIBUTOR = { method: 'post', endpoint: 'distributors' };
export const UPDATE_USER = { method: 'put', endpoint: (userID) => `users/${userID}` };
export const FORGOT_PASSWORD = { method: 'put', endpoint: 'users/forgot_password' };
export const RESET_PASSWORD = { method: 'put', endpoint: (encryptedUrl) => `users/reset_password${encryptedUrl}` };
export const CHANGE_USER_PASSWORD = { method: 'put', endpoint: (userID) => `users/${userID}/password` };
export const UPDATE_LANGUAGE = { method: 'put', endpoint: 'users/language' };
export const USER_STATUSES = { method: 'get', endpoint: 'users/statuses' };
export const USER_EXISTS = { method: 'get', endpoint: (user) => `user/exists?user=${user}` };
export const USER_INFO = { method: 'get', endpoint: (user) => `user/info?user=${user}` };
export const GET_TFA_QR_CODE = { method: 'get', endpoint: '2fa/preenable' };
export const ENABLE_TFA = { method: 'post', endpoint: '2fa/enable' };
export const DISABLE_TFA = { method: 'post', endpoint: '2fa/disable' };

// Popups
export const GET_SHOWABLE_POPUPS = { method: 'get', endpoint: (userId) => `users/${userId}/popups` };
export const GET_SHOWABLE_MEDIA_POPUPS = { method: 'get', endpoint: (userId) => `users/${userId}/media_popups` };
export const HIDE_POPUP = { method: 'patch', endpoint: (userId, popupId) => `users/${userId}/popups/${popupId}` };

// Profile
export const PROFILE_INFO = { method: 'get', endpoint: (userID) => `users/${userID}/profile` };
export const DUPLICATED_PROFILE_INFO = { method: 'get', endpoint: (userID, field) => `users/${userID}/duplicated_info/${field}` };
export const ACCOUNT_INFORMATION = { method: 'get', endpoint: (userID) => `distributors/${userID}/account_information` };
export const UPDATE_ACCOUNT = { method: 'put', endpoint: (userID) => `distributors/${userID}/account_information` };
export const REPLICATED_SITE = { method: 'get', endpoint: (userID) => `users/${userID}/replicated_site` };
export const REPLICATED_SITE_NAV = { method: 'get', endpoint: (replicatedId) => `distributors/replicated_site/${replicatedId}` };
export const UPDATE_REPLICATED_SITE = { method: 'put', endpoint: (userID) => `users/${userID}/replicated_site` };
export const CO_APPLICANTS = { method: 'get', endpoint: (userID) => `distributors/${userID}/coapplicants` };
export const UPDATE_PROFILE = { method: 'put', endpoint: (userID) => `users/${userID}/profile` };
export const UPDATE_PERSONAL_INFO = { method: 'put', endpoint: (userID) => `users/${userID}/personal_info` };
export const SUSPEND_ACCOUNT = { method: 'patch', endpoint: (userID) => `users/${userID}/suspend` };
export const TERMINATE_ACCOUNT = { method: 'patch', endpoint: (userID) => `users/${userID}/terminate` };
export const RESIGN_ACCOUNT = { method: 'patch', endpoint: (userID) => `users/${userID}/resign` };
export const REACTIVATE_ACCOUNT = { method: 'patch', endpoint: (userID) => `users/${userID}/reactivate` };
export const UPDATE_ACCOUNT_INFO = { method: 'put', endpoint: (userID) => `distributors/${userID}/account_info` };

// User Files
export const UPLOAD_USER_FILE = { method: 'post', endpoint: (userID) => `users/${userID}/file` };
export const GET_USER_FILES = { method: 'get', endpoint: (userID) => `users/${userID}/file` };
export const DELETE_USER_FILE = { method: 'put', endpoint: (userFileId) => `users/file/${userFileId}` };

// Leaders
export const GET_LEADERS = { method: 'get', endpoint: 'distributors/leaders' };
export const GET_LEADERBOARD_LEADERS = { method: 'get', endpoint: 'distributors/leaderboard_leaders' };

// Corporates
export const GET_CORPORATES = { method: 'get', endpoint: 'corporates' };

// Downlines Info
export const GET_DOWNLINES_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/downlines_info` };
export const GET_DOWNLINES_BVS_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/downlines_info/bvs` };
export const DOWNLOAD_DOWNLINES_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/downlines_info/download` };

// Customers
export const GET_CUSTOMERS = { method: 'get', endpoint: 'customers' };
export const UPGRADE_TO_AFFILIATE = { method: 'post', endpoint: 'customers/become_affiliate' };

// Permissions
export const GET_PERMISSIONS = { method: 'get', endpoint: (userId) => ((typeof userId === 'undefined') ? 'permissions' : `user/${userId}/permissions`) };
export const UPDATE_PERMISSION = { method: 'put', endpoint: (corporateId) => `user/${corporateId}/permissions` };

// Language
export const GET_LANGUAGES = { method: 'get', endpoint: 'languages' };

// Picture
export const GET_PROFILE_PICTURE = {
	method: 'get',
	endpoint: (userID, size) => {
		let requestedSize = '/';
		if (typeof size !== 'undefined') {
			requestedSize = `/${size}`;
		}
		return `users/${userID}/profile_photo${requestedSize}`;
	},
};
export const SAVE_PROFILE_PICTURE = { method: 'post', endpoint: (userID) => `users/${userID}/profile_photo` };
export const DELETE_PROFILE_PICTURE = { method: 'delete', endpoint: (userID) => `users/${userID}/profile_photo` };

// Sponsored
export const SPONSORED_DISTRIBUTORS = { method: 'get', endpoint: (userID) => `distributors/${userID}/sponsored_distributors` };
export const SPONSORED_CUSTOMERS = { method: 'get', endpoint: (userID) => `distributors/${userID}/sponsored_customers` };

// Countries
export const GET_COUNTRIES = { method: 'get', endpoint: (queryParams) => `countries${queryParams}` };
export const GET_REPORT_COUNTRIES = { method: 'get', endpoint: (queryParams) => `report_countries${queryParams}` };
export const GET_BILLING_COUNTRIES = { method: 'get', endpoint: (queryParams) => `billing_countries${queryParams}` };
export const FETCH_COUNTRY_BY_IP = { method: 'get', endpoint: `https://pro.ip-api.com/json/?fields=country,countryCode&key=${IP_API_KEY}` };
export const GET_STATES = { method: 'get', endpoint: (countryCode) => `countries/${countryCode}/regions`, module: 'Countries' };
export const GET_STATE = { method: 'get', endpoint: (region) => `regions/${region}` };
export const GET_SUBURBS = { method: 'get', endpoint: (postalCode) => `suburbs/${postalCode}` };
export const GET_COUNTRY = { method: 'get', endpoint: (countryCode) => `countries/${countryCode}` };
export const GET_CITIES = { method: 'get', endpoint: (region) => `regions/${region}/cities` };
export const GET_DISTRICTS = { method: 'get', endpoint: (city) => `cities/${city}/districts` };

// Companies
export const GET_REPORT_COMPANIES = { method: 'get', endpoint: (queryParams) => `report_companies${queryParams}` };

// Credit Cards
export const GET_CREDIT_CARDS = { method: 'get', endpoint: (userID) => `users/${userID}/cards`, module: 'CreditCards' };
export const GET_CREDIT_CARD = { method: 'get', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}`, module: 'CreditCards' };
export const DELETE_CREDIT_CARD = { method: 'delete', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}`, module: 'CreditCards' };
export const CREATE_CREDIT_CARD = { method: 'post', endpoint: (userID) => `users/${userID}/cards`, module: 'CreditCards' };
export const PICKUP_CREATE_CREDIT_CARD = { method: 'post', endpoint: (userID) => `pickup/users/${userID}/cards`, module: 'CreditCards' };
export const UPDATE_CREDIT_CARD = { method: 'put', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}`, module: 'CreditCards' };
export const UPDATE_CREDIT_CARD_BILLING = { method: 'put', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}/address`, module: 'CreditCards' };
export const SET_DEFAULT_CREDIT_CARD = { method: 'patch', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}/default`, module: 'CreditCards' };
export const SET_BACKUP_CARD = { method: 'patch', endpoint: (userID) => `users/${userID}/cards/backup_card`, module: 'CreditCards' };
export const SORT_CREDIT_CARD = { method: 'patch', endpoint: (userID, cardID) => `users/${userID}/cards/${cardID}/sort`, module: 'CreditCards' };
export const SET_BACKUP_CARD_AS_DEFAULT = { method: 'patch', endpoint: (userID) => `users/${userID}/cards/backup_card_default`, module: 'CreditCards' };

// Addresses
export const SAVE_ADDRESS = { method: 'post', endpoint: (userID) => `users/${userID}/addresses`, module: 'Addresses' };
export const GET_ADDRESSES = { method: 'get', endpoint: (userID) => `users/${userID}/addresses`, module: 'Addresses' };
export const GET_ADDRESS = { method: 'get', endpoint: (userID, addressID) => `users/${userID}/addresses/${addressID}`, module: 'Addresses' };
export const GET_ADDRESS_DOCUMENT = { method: 'get', endpoint: (userID, addressID, side) => `users/${userID}/addresses/${addressID}/personal_document?side=${side}`, module: 'Addresses' };
export const PICKUP_SAVE_ADDRESS = { method: 'post', endpoint: (userID) => `pickup/users/${userID}/addresses`, module: 'Addresses' };
export const PICKUP_GET_ADDRESSES = { method: 'get', endpoint: (userID) => `pickup/users/${userID}/addresses`, module: 'Addresses' };
export const PICKUP_GET_ADDRESS = { method: 'get', endpoint: (userID, addressID) => `pickup/users/${userID}/addresses/${addressID}`, module: 'Addresses' };
export const DELETE_ADDRESS = { method: 'delete', endpoint: (userID, addressID) => `users/${userID}/addresses/${addressID}`, module: 'Addresses' };
export const UPDATE_ADDRESS = { method: 'post', endpoint: (userID, addressID) => `users/${userID}/addresses/${addressID}?_method=PUT`, module: 'Addresses' };
export const UPDATE_RECURRING_ADDRESS = { method: 'put', endpoint: (userID, addressID) => `users/${userID}/addresses/${addressID}/default`, module: 'Addresses' };
export const ADDRESS_SUGGESTION = { method: 'get', endpoint: 'address_suggestion', module: 'Addresses' };

// Products
export const GET_PRODUCTS = { method: 'get', endpoint: 'products' };
export const PICKUP_GET_PRODUCTS = { method: 'get', endpoint: 'pickup/products' };
export const GET_ALL_PRODUCTS = { method: 'get', endpoint: (queryParams) => `all_products${queryParams}` };
export const GET_PRODUCT_GROUPS = { method: 'get', endpoint: 'product_groups' };
export const GET_MEMBERSHIP = { method: 'get', endpoint: 'membership' };
export const GET_ACCOUNT_TRANSFER_FEE = { method: 'get', endpoint: 'account_transfer_fee' };
export const GET_PRODUCT_DETAILS = { method: 'get', endpoint: (productName) => `products/${productName}` };
export const GET_MANY_PRODUCT_DETAILS = { method: 'get', endpoint: (queryParams) => `products_detail${queryParams}` };
export const PICKUP_GET_PRODUCT_DETAILS = { method: 'get', endpoint: (productName) => `pickup/products/${productName}` };
export const GET_PROMOTION_DETAILS = { method: 'get', endpoint: (promoName) => `bundle_promotions/${promoName}` };
export const GET_PROMOTIONS = { method: 'get', endpoint: 'bundle_promotions' };

// Free Virtual Events
export const GET_FREE_VIRTUAL_EVENTS = { method: 'get', endpoint: 'free_virtual_events' };
export const GENERATE_FREE_VIRTUAL_EVENT_TICKET = { method: 'post', endpoint: 'free_virtual_events' };

// Autoship
export const GET_AUTOSHIP_PRODUCTS = { method: 'get', endpoint: (userID) => `users/${userID}/autoship/products` };
export const GET_AUTOSHIP = { method: 'get', endpoint: (userID) => `users/${userID}/autoship` };
export const UPDATE_AUTOSHIP = { method: 'put', endpoint: (userID) => `users/${userID}/autoship` };
export const ADD_AUTOSHIP_PRODUCT = { method: 'put', endpoint: (userID) => `users/${userID}/autoship/cart` };
export const GET_AUTOSHIP_TOTALS = { method: 'get', endpoint: (userID) => `users/${userID}/autoship/cart` };

// Update Autoship (from onboarding and become affiliate)
export const UPDATE_AUTOSHIP_INFO = { method: 'put', endpoint: 'autoship/fill_autoship_info' };

// Autoship Customers
export const SET_NEXT_AUTOSHIP_DATE = { method: 'patch', endpoint: (userID) => `users/${userID}/autoship/set_date` };

// Autoship Periodicity
export const SET_AUTOSHIP_PERIODICITY = { method: 'patch', endpoint: (userID) => `users/${userID}/autoship/set_periodicity` };

// Cart
export const GET_CART = { method: 'get', endpoint: (cartId) => `carts/${cartId}/totals` };
export const PICKUP_GET_CART = { method: 'get', endpoint: (cartId) => `pickup/carts/${cartId}/totals` };
export const CREATE_CART = { method: 'post', endpoint: 'carts' };
export const PICKUP_CREATE_CART = { method: 'post', endpoint: 'pickup/carts' };
export const REMOVE_CART = { method: 'delete', endpoint: (cartId) => `carts/${cartId}` };
export const GET_CART_INFO = { method: 'get', endpoint: (cartId) => `carts/${cartId}/info` };
export const ADD_CART_COUPON = { method: 'post', endpoint: (cartId) => `carts/${cartId}/coupon` };
export const REMOVE_CART_COUPON = { method: 'put', endpoint: (cartId) => `carts/${cartId}/coupon` };
export const ADD_CART_PRODUCTS = { method: 'post', endpoint: (cartId) => `carts/${cartId}/products` };
export const PICKUP_ADD_CART_PRODUCTS = { method: 'post', endpoint: (cartId) => `pickup/carts/${cartId}/products` };
// export const GET_RENEWAL_CART = { method: 'get', endpoint: (cartId) => `carts/${cartId}/renewal_totals` };
export const GET_CARTS_ESTIMATED_TOTALS = { method: 'post', endpoint: 'carts/estimated_totals' };
export const USE_POINTS = { method: 'patch', endpoint: (cartId) => `carts/${cartId}/use_points` };
export const REMOVE_POINTS = { method: 'patch', endpoint: (cartId) => `carts/${cartId}/remove_points` };
export const SPLIT_PAYMENT = { method: 'post', endpoint: (cartId) => `carts/${cartId}/split_payment` };

// Gift Orders
export const GET_GIFT_ORDERS = { method: 'get', endpoint: 'gift_orders/all' };
export const CREATE_GIFT_ORDER_CART = { method: 'post', endpoint: 'gift_order/cart' };
export const CREATE_GIFT_ORDER_PACK_CART = { method: 'post', endpoint: 'gift_order/pack_cart' };
export const UPDATE_GIFT_ORDER_CART_INFO = { method: 'put', endpoint: 'gift_order/info' };
export const CREATE_GIFT_ORDER = { method: 'post', endpoint: 'gift_order' };

// Search
export const SEARCH_USERS = { method: 'get', endpoint: 'users/search' };

// Tree
export const GET_BINARY_TREE = { method: 'get', endpoint: (queryParams) => `distributors/binary_tree${queryParams}` };
export const GET_BINARY_TREE_VOLUME = { method: 'get', endpoint: (queryParams) => `distributors/binary_tree/volume${queryParams}` };
export const GET_BINARY_TREE_INFORMATION = { method: 'get', endpoint: (queryParams) => `distributors/binary_tree/information${queryParams}` };
export const BINARY_TREE_MOVE_NODE = { method: 'post', endpoint: 'distributors/binary_tree/move_node' };
export const GET_OUTER_NODES = { method: 'get', endpoint: (id) => `distributors/outer_nodes?id=${id}` };
export const GET_UNILEVEL_TREE = { method: 'get', endpoint: (queryParams) => `distributors/unilevel_tree${queryParams}` };
export const GET_UNILEVEL_METADATA = { method: 'get', endpoint: (queryParams) => `distributors/unilevel_tree/metadata${queryParams}` };
export const GET_UNILEVEL_INFO = { method: 'get', endpoint: (queryParams) => `distributors/unilevel_tree/info${queryParams}` };

// Network Summaries
export const GET_UNILEVEL_SUMMARY = { method: 'get', endpoint: (queryParams) => `distributors/unilevel_summary${queryParams}` };
export const GET_UNILEVEL_SUMMARY_TERMS = { method: 'get', endpoint: 'distributors/unilevel_summary/terms' };
export const ACCEPT_UNILEVEL_SUMMARY_TERMS = { method: 'put', endpoint: 'distributors/unilevel_summary/terms' };
export const GET_UNILEVEL_SUMMARY_DETAILS = { method: 'get', endpoint: (queryParams) => `distributors/unilevel_summary/details${queryParams}` };

// Dashboard
export const DASHBOARD_ACCOUNT_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/account_information` };
export const DASHBOARD_ACCOUNT_INFO_CUSTOMER = { method: 'get', endpoint: (userID) => `customers/${userID}/dashboard/account_information` };
export const DASHBOARD_SHOW_INTRO_POPUP = { method: 'get', endpoint: 'distributors/intro_popup' };
export const DASHBOARD_HIDE_INTRO_POPUP = { method: 'put', endpoint: 'distributors/intro_popup' };
export const DASHBOARD_USER_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/user_information` };
export const DASHBOARD_INCOME = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/income` };
export const DASHBOARD_ORGANIZATION_NOTIFICATIONS = { method: 'get', endpoint: (userID) => `users/${userID}/dashboard/organization_notifications` };
export const DASHBOARD_VOLUME_COMPARITION = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/volume_comparison` };
export const DASHBOARD_DISTRIBUTOR_PERIOD_INFO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/period_info` };
export const DASHBOARD_VOLUME_HISTORY = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/volume_history` };
export const DASHBOARD_PROMOTIONAL_INCOME_WIDGETS = { method: 'get', endpoint: (userID) => `distributors/${userID}/promotional_income/widgets` };
export const DASHBOARD_REFERRAL_BONUS = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/referral_bonus` };
export const DASHBOARD_DOUBLE_REFERRAL_BONUS_PROMO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/double_referral_bonus_promo` };
export const DASHBOARD_ECOMMERCE_PROMO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/ecommerce_promo` };
export const DASHBOARD_RETAIL_PROFIT_BONUS_PROMO = { method: 'get', endpoint: (userID) => `users/${userID}/dashboard/retail_profit_bonus` };
export const DASHBOARD_STRIVE_FOR_FIVE_PROMO = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/strive_for_five` };
export const DASHBOARD_FAST_START_BONUS = { method: 'get', endpoint: (userID) => `distributors/${userID}/dashboard/fast_start_bonus` };
export const DATE_RANGE_THIS_MONTH = { method: 'get', endpoint: 'common/date_range?dateRange=current_month' };
export const DASHBOARD_LEADERBOARD = { method: 'get', endpoint: 'dashboard/leaderboard' };
export const SHOW_DASHBOARD_LEADERBOARD = { method: 'get', endpoint: (userID) => `distributors/${userID}/show_leaderboard` };

export const DASHBOARD_GLOBAL_KPIS = { method: 'get', endpoint: 'dashboard/global_kpis' };
export const DASHBOARD_PERIOD_INFO = { method: 'get', endpoint: 'dashboard/period_info' };
export const DASHBOARD_PERIOD_INFO_USER = { method: 'get', endpoint: (userId) => `dashboard/${userId}/period_info` };
export const DASHBOARD_SALES = { method: 'get', endpoint: 'dashboard/sales' };
export const DASHBOARD_SALES_USER = { method: 'get', endpoint: (userId) => `dashboard/${userId}/sales` };
export const DASHBOARD_NEW_REGISTRATIONS = { method: 'get', endpoint: 'dashboard/new_registrations' };
export const DASHBOARD_ORGANIZATION_RANKS = { method: 'get', endpoint: 'dashboard/organization_ranks' };
export const DASHBOARD_ORGANIZATION_PACKAGES = { method: 'get', endpoint: 'dashboard/organization_packages' };
export const SHOW_DASHBOARD_REGIONAL_SALES = { method: 'get', endpoint: 'dashboard/show_regional_sales' };
export const DASHBOARD_REGIONAL_SALES = { method: 'get', endpoint: 'dashboard/regional_sales' };
export const DASHBOARD_REGIONAL_SALES_USER = { method: 'get', endpoint: (userId) => `dashboard/${userId}/regional_sales` };
export const DASHBOARD_COUNTRY_SALES = { method: 'get', endpoint: 'dashboard/country_sales' };
export const DASHBOARD_COUNTRY_SALES_USER = { method: 'get', endpoint: (userId) => `dashboard/${userId}/country_sales` };
export const DASHBOARD_TOP_INCOME_EARNERS = { method: 'get', endpoint: 'dashboard/top_income_earners' };
export const DASHBOARD_TOP_USER_STATS = { method: 'get', endpoint: 'dashboard/top_user_stats' };

// User promotions
export const FREE_PRODUCTS_PROMO_ALERTS = { method: 'get', endpoint: (userId) => `users/${userId}/promotions/alert` };
export const FREE_PRODUCTS_PROMO_PRODUCTS = { method: 'get', endpoint: (userId) => `users/${userId}/promotions/products` };
export const UPDATE_FREE_PRODUCTS_PROMO_PRODUCTS = { method: 'put', endpoint: (userId) => `users/${userId}/promotions/products` };

// Onboarding
export const ONBOARDING_PROCESS = { method: 'put', endpoint: 'users/onboarding_process' };

// No discounts store
export const ND_CREATE_CART = { method: 'post', endpoint: 'store/carts' };
export const ND_REMOVE_CART = { method: 'delete', endpoint: (cartId) => `store/carts/${cartId}` };
export const ND_CHECKOUT_VALIDATOR = { method: 'post', endpoint: 'store/user/checkout' };
export const ND_GET_PRODUCTS = { method: 'get', endpoint: 'store/products' };
export const ND_GET_PRODUCT_DETAILS = { method: 'get', endpoint: (productName) => `store/products/${productName}` };
export const ND_GET_CART = { method: 'get', endpoint: (cartId) => `store/carts/${cartId}/totals` };

// Ranks
export const GET_DISTRIBUTOR_RANKS = { method: 'get', endpoint: 'distributors/ranks' };
export const GET_DISTRIBUTOR_ELITE_LEVELS = { method: 'get', endpoint: 'distributors/elite_levels' };

// Rank Progress
export const GET_RANK_PROGRESS = { method: 'get', endpoint: (userId) => `distributors/${userId}/rank_progress` };
export const GET_RANK_ALERT = { method: 'get', endpoint: 'rank_progress/alerts' };
export const UPDATE_RANK_ALERT = { method: 'put', endpoint: 'rank_progress/alerts' };

// Packages
export const GET_PRODUCTS_PACKAGES = { method: 'get', endpoint: (queryParams) => `packages${queryParams}` };
export const GET_AVAILABLE_PACKAGES = { method: 'get', endpoint: (userId) => `users/${userId}/upgrade_packages` };

// Shipping
export const GET_CART_SHIPPING_METHOD = { method: 'get', endpoint: (cartId) => `carts/${cartId}/shipping_methods` };
export const GET_CART_PAYMENT_METHOD = { method: 'get', endpoint: 'payment_methods' };
export const PICKUP_GET_CART_PAYMENT_METHOD = { method: 'get', endpoint: 'pickup/payment_methods' };

// Purchase Flows
export const REGISTER_VALIDATOR = { method: 'post', endpoint: 'user/register' };
export const GET_REGISTER_PACKS = { method: 'get', endpoint: (queryParams) => `user/register/packs${queryParams}` };
export const GET_TRANSFER_FEE_PACKS = { method: 'get', endpoint: 'account_transfer/packages' };
export const PACKS_CALCULATION = { method: 'post', endpoint: 'user/register/packs' };
export const BECOME_DISTRIBUTOR_VALIDATOR = { method: 'post', endpoint: 'customers/become_distributor' };
export const CHECKOUT_VALIDATOR = { method: 'post', endpoint: 'user/checkout' };
export const GET_RENEWAL_CART = { method: 'get', endpoint: (user, membership) => `users/${user}/renew_membership?membership=${membership}` };
export const RENEWAL_VALIDATOR = { method: 'post', endpoint: (user) => `users/${user}/renew_membership` };
export const TRANSFER_FEE_VALIDATOR = { method: 'post', endpoint: (user) => `users/${user}/account_transfer` };
export const PREREGISTER_VALIDATOR = { method: 'post', endpoint: 'user/pre_register' };
export const PURCHASE_INFORMATION = { method: 'get', endpoint: 'purchase/info' };
export const UPDATE_PURCHASE_INFORMATION = { method: 'put', endpoint: 'purchase/info' };
export const PICKUP_PURCHASE_INFORMATION = { method: 'get', endpoint: 'pickup/purchase/info' };
export const PICKUP_UPDATE_PURCHASE_INFORMATION = { method: 'put', endpoint: 'pickup/purchase/info' };
export const PURCHASE = { method: 'post', endpoint: 'purchase' };
export const PICKUP_PURCHASE = { method: 'post', endpoint: 'pickup/purchase' };
export const PACK_PURCHASE = { method: 'post', endpoint: 'purchase/pack_cart' };
export const RETRY_EXTERNAL_PAYMENT = { method: 'post', endpoint: (orderId) => `external_payments/${orderId}/retry` };
export const RETRY_PAYMENT = { method: 'post', endpoint: (queryParams) => `external_payments/retry${queryParams}` };
export const CREATE_AFFILIATE = { method: 'post', endpoint: 'affiliates' };

// Payment processor
export const CREATE_IDENTITY = { method: 'post', endpoint: 'identities' };
export const CREATE_IDENTITY_V2 = { method: 'post', endpoint: 'identitiesV2' };

// Crypto Payments
export const GET_CRYPTO_PAYMENT_DETAILS = { method: 'get', endpoint: (walletAddress) => `crypto_payments/details/${walletAddress}` };

// Payment methods
export const GET_ALL_PAYMENT_METHODS = { method: 'get', endpoint: 'all_payment_methods' };

// Weekly commissions
export const GET_COMMISIONS_WEEK_TOTALS = { method: 'get', endpoint: 'reports/commissions/totals' };
export const GET_COMMISIONS_RETAIL_PROFIT = { method: 'get', endpoint: 'reports/commissions/retail_profit' };
export const GET_COMMISIONS_START_BONUS = { method: 'get', endpoint: 'reports/commissions/fast_start_bonus' };
export const GET_REFERRAL_BONUS_MULTIPLIER = { method: 'get', endpoint: 'reports/commissions/referral_bonus_multiplier' };
export const GET_COMMISIONS_TEAM_COMMISION = { method: 'get', endpoint: 'reports/commissions/team_commission' };
export const GET_COMMISIONS_MATCHING_BONUS = { method: 'get', endpoint: 'reports/commissions/leadership_matching_bonus' };
export const GET_COMMISIONS_BONUS_POOL = { method: 'get', endpoint: 'reports/commissions/leadership_bonus_pool' };
export const GET_COMMISIONS_LIFESTYLE_BONUS = { method: 'get', endpoint: 'reports/commissions/lifestyle_bonus' };
export const GET_DUDD_BONUS = { method: 'get', endpoint: 'reports/commissions/double_up_double_down' };
export const GET_ECOMMERCE_PROMO_BONUS = { method: 'get', endpoint: 'reports/commissions/ecommerce_promotion' };
export const GET_STRIVE_FOR_FIVE_BONUS = { method: 'get', endpoint: 'reports/commissions/strive_for_five' };
export const GET_RETAIL_PROFIT_ONLINE_BONUS = { method: 'get', endpoint: 'reports/commissions/online_retail_profit' };
export const GET_COMMISIONS_TOP_EARNERS = { method: 'get', endpoint: 'reports/commissions/top_earners' };
export const GET_COMMISIONS_ORDERS = { method: 'get', endpoint: 'reports/commissions/sales' };
export const GET_COMMISIONS_REFUNDS = { method: 'get', endpoint: 'reports/commissions/refunds' };
export const GET_COMMISIONS_PROMOTIONS = { method: 'get', endpoint: 'reports/commissions/promotions' };
export const APPROVE_COMMISIONS = { method: 'put', endpoint: 'commissions/weeks/approve' };
export const RELEASE_COMMISIONS = { method: 'put', endpoint: 'commissions/weeks/release' };
export const DOWNLOAD_WEEKLY_COMMISSIONS_REPORT = { method: 'get', endpoint: 'reports/commissions/download' };

// Weekly payouts
export const GET_PAYOUTS = { method: 'get', endpoint: 'commissions/weeks' };
export const GET_PAYOUTS_WEEK_TOTALS = { method: 'get', endpoint: 'commissions/weeks/totals' };
export const PAYOUT = { method: 'put', endpoint: 'commissions/payout' };

// Weeks
export const GET_WEEKS = { method: 'get', endpoint: 'weeks' };

// Phone verification
export const VERIFY_PHONE = { method: 'post', endpoint: 'verification/phone' };
export const VERIFY_PHONE_CODE = { method: 'post', endpoint: 'verification/phone_code' };
export const VERIFY_PHONE_ORGANIZATION = { method: 'get', endpoint: (userId) => `verification/${userId}/verified_organization` };

// Distributors information
export const GET_DISTRIBUTORS_COMMISSIONS = { method: 'get', endpoint: (userId) => `users/${userId}/commissions` };
export const GET_DISTRIBUTORS_PROFILE = { method: 'get', endpoint: (userId) => `users/${userId}/profile` };
export const GET_DISTRIBUTORS_ACCOUNT_INFORMATION = { method: 'get', endpoint: (userId) => `distributors/${userId}/account_information` };
export const GET_DISTRIBUTOR_CAN_CHECKOUT = { method: 'get', endpoint: 'distributors/can_checkout' };
export const GET_OFFICIAL_DOCUMENT = { method: 'get', endpoint: (queryParams) => `distributors/official_document${queryParams}` };

// Customers information
export const GET_CUSTOMERS_PROFILE = { method: 'get', endpoint: (userId) => `users/${userId}/profile` };
export const GET_CUSTOMERS_PROFILE_ACCOUNT_INFORMATION = { method: 'get', endpoint: (userId) => `customers/${userId}/profile_account_information` };
export const GET_CUSTOMERS_ACCOUNT_INFORMATION = { method: 'get', endpoint: (userId) => `customers/${userId}/account_information` };
export const UPDATE_CUSTOMER_ACCOUNT_INFO = { method: 'patch', endpoint: (userId) => `customers/${userId}/account_information` };

// Create Ticket
export const CREATE_TICKET = { method: 'post', endpoint: 'tickets' };

// Event Tickets
export const CREATE_EVENT_TICKET = { method: 'post', endpoint: 'reports/event_tickets' };
export const UPDATE_EVENT_TICKET_NAMES = { method: 'put', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/names` };
export const UPDATE_EVENT_TICKET_NOTES = { method: 'put', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/notes` };
export const UPDATE_EVENT_TICKET_REGION = { method: 'put', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/region` };
export const TRANSFER_EVENT_TICKET = { method: 'patch', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/transfer` };
export const USER_EVENT_TICKET_RESERVATION = { method: 'patch', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/reservation` };
export const DELETE_EVENT_TICKET = { method: 'put', endpoint: (ticketId) => `reports/event_tickets/${ticketId}/delete` };
export const GET_TICKET = { method: 'get', endpoint: (ticketId) => `reports/event_tickets/${ticketId}` };
export const GET_TICKETS = { method: 'get', endpoint: 'reports/event_tickets' };
export const GET_ALL_TICKETS = { method: 'get', endpoint: 'reports/event_tickets/confirm/all' }; // This endpoint has no pagination
export const GET_TICKETS_TO_CONFIRM = { method: 'get', endpoint: (ticketNumber) => `reports/event_tickets/confirm/${ticketNumber}` };
export const CONFIRM_TICKETS = { method: 'patch', endpoint: 'reports/event_tickets/confirm' };
export const GET_CHECKIN_SUMMARY = { method: 'get', endpoint: (queryParams) => `reports/event_tickets/confirm/summary${queryParams}` };
export const GET_HOTEL_INFORMATION = { method: 'get', endpoint: 'reports/event_tickets/hotel_information' };
export const DOWNLOAD_TICKETS = { method: 'get', endpoint: 'reports/event_tickets/download' };
export const DOWNLOAD_HOTEL_INFORMATION = { method: 'get', endpoint: 'reports/event_tickets/download_hotel_information' };
export const GET_TICKETS_PAYMENTS = { method: 'get', endpoint: 'reports/event_tickets/payments' };
export const GET_TICKET_PAYMENT_DETAILS = { method: 'get', endpoint: (paymentId) => `reports/event_tickets/payments/${paymentId}` };
export const GET_FREE_EVENT_TICKETS = { method: 'get', endpoint: 'free_event_tickets' };
export const GET_FREE_EVENT_TICKETS_DATES = { method: 'get', endpoint: (eventCodeName) => `free_event_tickets/${eventCodeName}` };
export const CREATE_FREE_EVENT_TICKETS = { method: 'put', endpoint: 'free_event_tickets' };
export const GET_FREE_TICKET_INFO = { method: 'get', endpoint: (eventId) => `free_event_tickets/event_info/${eventId}` };
export const DELETE_FREE_TICKET = { method: 'delete', endpoint: (eventId) => `free_event_tickets/${eventId}` };

// Register Customer
export const VALIDATE_CUSTOMER = { method: 'post', endpoint: 'user/register_customer' };
export const REGISTER_CUSTOMER = { method: 'post', endpoint: 'user/register_customer/registration' };

// System Credentials
export const GET_SYSTEM_CREDENTIALS = { method: 'get', endpoint: (userId) => `users/${userId}/system_credentials` };
export const UPDATE_SYSTEM_CREDENTIALS = { method: 'put', endpoint: (userId) => `users/${userId}/system_credentials` };

// Settings
export const EXECUTE_COMMAND = { method: 'post', endpoint: 'commands' };

// Announcements
export const GET_ANNOUNCEMENT = { method: 'get', endpoint: (announcementId) => `announcements/${announcementId}` };
export const GET_ANNOUNCEMENTS = { method: 'get', endpoint: 'announcements' };
export const GET_ANNOUNCEMENTS_LIST = { method: 'get', endpoint: (userId) => `distributors/${userId}/announcements/list` };
export const HIDE_ANNOUNCEMENT = { method: 'get', endpoint: (userId, announcementId) => `distributors/${userId}/announcements/${announcementId}/hide` };
export const CREATE_ANNOUNCEMENT = { method: 'post', endpoint: 'announcements' };
export const UPDATE_ANNOUNCEMENT = { method: 'put', endpoint: (announcementId) => `announcements/${announcementId}` };
export const REMOVE_ANNOUNCEMENT = { method: 'put', endpoint: (announcementId) => `announcements/${announcementId}/delete` };

// Notifications
export const GET_NOTIFICATIONS = { method: 'get', endpoint: 'notifications' };
export const GET_NOTIFICATIONS_INFO = { method: 'get', endpoint: 'notifications/info' };
export const MARK_NOTIFICATIONS_AS_SEEN = { method: 'put', endpoint: 'notifications/mark_as_seen' };

// Sms Notifications
export const GET_SMS = { method: 'get', endpoint: 'sms' };
export const GET_SMS_RECIPIENTS = { method: 'get', endpoint: 'sms/recipients' };
export const CREATE_SMS = { method: 'post', endpoint: 'sms' };

// Push Notifications
export const GET_PUSH_NOTIFICATIONS = { method: 'get', endpoint: 'push_notifications' };
export const GET_PUSH_NOTIFICATIONS_RECIPIENTS = { method: 'get', endpoint: 'push_notifications/recipients' };
export const CREATE_PUSH_NOTIFICATION = { method: 'post', endpoint: 'push_notifications/send' };
export const CANCEL_PUSH_NOTIFICATION = { method: 'patch', endpoint: (notificationId) => `push_notifications/${notificationId}/cancel` };

// Geofences
export const GET_GEOFENCES = { method: 'get', endpoint: 'geofences' };
export const CREATE_GEOFENCE = { method: 'post', endpoint: 'geofences' };
export const GET_ADDRESS_FROM_COORDS = { method: 'get', endpoint: (lat, lng) => `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}` };

// BV
export const RESTORE_BV = { method: 'put', endpoint: (bvId) => `bv/${bvId}/restore` };

// Wallets
export const GET_PAYOUT_METHODS = { method: 'get', endpoint: 'payout_methods' };
export const GET_WALLETS_BALANCE = { method: 'get', endpoint: 'wallets/balance' };
export const GET_USER_WALLETS_BALANCE = { method: 'get', endpoint: 'wallets/user_balance' };
export const GET_ALL_WALLETS_BALANCES = { method: 'get', endpoint: 'wallets/balances' };
export const DOWNLOAD_WALLET_BALANCES = { method: 'get', endpoint: 'wallets/balances/download' };
export const GET_WALLETS_TRANSACTIONS = { method: 'get', endpoint: (walletType) => `wallets/transactions/${walletType}` };
export const CREATE_PAYOUT_ACCOUNT = { method: 'post', endpoint: (userId) => `users/${userId}/payout/account` };
export const UPDATE_PAYOUT_ACCOUNT_ALERT = { method: 'put', endpoint: (userId) => `users/${userId}/payout/alert` };
export const GET_PAYOUT_ACCOUNT = { method: 'get', endpoint: (userId) => `users/${userId}/payout/login` };
export const CREATE_WITHDRAWAL = { method: 'post', endpoint: 'wallets/withdrawal' };
export const CANCEL_WITHDRAWAL = { method: 'put', endpoint: (transaction) => `wallets/withdrawal/${transaction}` };
export const UPDATE_WALLET_PASSWORD = { method: 'patch', endpoint: (userId) => `users/${userId}/wallets/password` };
export const GET_WALLET_ALERTS = { method: 'get', endpoint: 'wallets/alerts' };
export const UPDATE_WALLET_ALERT = { method: 'put', endpoint: (transactionId) => `wallets/alerts/${transactionId}` };
export const CREATE_TRANSFER = { method: 'post', endpoint: 'wallets/transfer' };
export const GET_BANKS = { method: 'get', endpoint: 'banks' };
export const GET_BANK_BRANCHES = { method: 'get', endpoint: (bankId) => `banks/${bankId}/branches` };
export const SAVE_BANK_INFORMATION = { method: 'post', endpoint: 'user_bank' };
export const GET_BANK_INFORMATION = { method: 'get', endpoint: 'user_bank' };
export const DOWNLOAD_TW_LOCAL_EFT_WITHDRAWALS = { method: 'get', endpoint: 'withdrawals/tw_local_eft/export' };

// Videos
export const GET_VIDEO_PROGRESS = { method: 'get', endpoint: (userId, name) => `users/${userId}/videos/progress?name=${name}` };
export const SAVE_VIDEO_PROGRESS = { method: 'put', endpoint: (userId) => `users/${userId}/videos/progress` };

// Points
export const ADD_POINTS = { method: 'post', endpoint: 'points' };
export const GET_POINTS_BALANCE = { method: 'get', endpoint: 'points/balance' };
export const GET_USER_POINTS_BALANCE = { method: 'get', endpoint: 'points/user_balance' };
export const GET_POINTS_TRANSACTIONS = { method: 'get', endpoint: 'points/transactions' };
export const GET_POINTS_SUMMARIES = { method: 'get', endpoint: 'points/summaries' };

// Coupons
export const GET_COUPONS = { method: 'get', endpoint: 'coupons' };
export const GENERATE_COUPONS = { method: 'post', endpoint: 'coupons' };
export const DELETE_COUPON = { method: 'delete', endpoint: (coupon) => `coupons/${coupon}` };

// Custom Carts
export const GET_CUSTOM_CARTS = { method: 'get', endpoint: 'custom_carts' };
export const CREATE_CUSTOM_CART = { method: 'post', endpoint: 'custom_carts' };
export const GET_CUSTOM_CART_PRODUCTS = { method: 'get', endpoint: (customCartId) => `custom_carts/${customCartId}/products` };
export const DELETE_CUSTOM_CART = { method: 'delete', endpoint: (customCartId) => `custom_carts/${customCartId}` };
export const RESTORE_CUSTOM_CART = { method: 'get', endpoint: (customCartUuid) => `custom_carts/${customCartUuid}/restore` };
export const GET_CUSTOM_CART = { method: 'get', endpoint: (customCartUuid) => `custom_carts/${customCartUuid}` };

// Store Settings
export const GET_EXCHANGE_RATES = { method: 'get', endpoint: 'exchange_rates' };
export const UPDATE_EXCHANGE_RATES = { method: 'put', endpoint: 'exchange_rates' };

// Invitations
export const GET_INVITATIONS = { method: 'get', endpoint: 'invitations' };
export const VERIFY_INVITATION = { method: 'get', endpoint: 'invitations/verification' };
export const CREATE_INVITATION = { method: 'post', endpoint: 'invitations' };
export const DELETE_INVITATION = { method: 'delete', endpoint: (invitation) => `invitations/${invitation}` };
export const RENOVATE_INVITATION = { method: 'put', endpoint: (invitation) => `invitations/${invitation}/renovate` };
export const INVITATIONS_USER_INFO = { method: 'get', endpoint: 'invitations/user_info' };

// export const GET_CREDITS = { method: 'get', endpoint: 'credits' };
export const ADD_CREDITS = { method: 'post', endpoint: 'credits' };

// Tags
export const TAGS = { method: 'get', endpoint: (queryParams) => `tags${queryParams}` };

// Inventory
export const GET_INVENTORY = { method: 'get', endpoint: 'inventory' };
export const GET_WAREHOUSES = { method: 'get', endpoint: (queryParams) => `inventory/warehouses${queryParams}` };
export const GET_INVENTORY_PRODUCTS = { method: 'get', endpoint: (queryParams) => `inventory/products${queryParams}` };
export const GET_INVENTORY_STATUSES = { method: 'get', endpoint: 'inventory/statuses' };
export const GET_INVENTORY_CATEGORIES = { method: 'get', endpoint: 'inventory/categories' };
export const UPDATE_STOCK_STATUS = { method: 'put', endpoint: 'inventory/stock_status' };
export const UPDATE_STOCK = { method: 'put', endpoint: 'inventory/stock' };
export const TRANSFER_STOCK = { method: 'put', endpoint: 'inventory/transfer' };
export const COMPLETE_STOCK_TRANSFER = { method: 'put', endpoint: (id) => `inventory/in_transit/complete/${id}` };
export const CANCEL_STOCK_TRANSFER = { method: 'put', endpoint: (id) => `inventory/in_transit/cancel/${id}` };
export const DOWNLOAD_INVENTORY = { method: 'get', endpoint: 'inventory/download' };
export const GET_INVENTORY_CHANGES = { method: 'get', endpoint: 'inventory/changes' };
export const GET_INVENTORY_IN_TRANSIT = { method: 'get', endpoint: 'inventory/in_transit' };
export const GET_INVENTORY_CHANGES_DETAIL = { method: 'get', endpoint: (id) => `inventory/changes/${id}` };
export const TOGGLE_ALLOW_BACKORDERS = { method: 'put', endpoint: 'inventory/allow_backorder' };

// Warehouse Orders
export const GET_WAREHOUSE_ORDERS_WAREHOUSES = { method: 'get', endpoint: 'warehouse_orders/warehouses' };
export const GET_PENDING_WAREHOUSE_ORDERS = { method: 'get', endpoint: (page, query) => `warehouse_orders/pending_orders?page=${page}&${query}` };
export const EXPORT_WAREHOUSE_ORDERS = { method: 'get', endpoint: 'warehouse_orders/export' };
export const GET_WAREHOUSE_ORDERS_BATCHES = { method: 'get', endpoint: (query) => `warehouse_orders/batches?${query}` };
export const EXPORT_WAREHOUSE_ORDERS_BATCH = { method: 'get', endpoint: 'warehouse_orders/export_batch' };
export const EXPORT_WAREHOUSE_ORDERS_IMPORT_TEMPLATE = { method: 'get', endpoint: (warehouseId) => `warehouse_orders/import_template?warehouse_id=${warehouseId}` };
export const IMPORT_WAREHOUSE_ORDERS = { method: 'post', endpoint: 'warehouse_orders/import' };
export const GET_WAREHOUSE_ORDERS_EXCEPTIONS = { method: 'get', endpoint: (warehouseId) => `warehouse_orders/exceptions?warehouse_id=${warehouseId}` };
export const GET_UNSHIPPED_ORDERS = { method: 'get', endpoint: (download, filters) => `orders/unshipped?download=${download}&${filters}` };
export const GET_GUI_INFORMATION = { method: 'get', endpoint: (download, filters) => `orders/gui_information?download=${download}&${filters}` };

// Product Reviews
export const GET_PRODUCT_REVIEWS = { method: 'get', endpoint: 'product_reviews' };
export const GET_PRODUCT_REVIEW_BY_ID = { method: 'get', endpoint: (reviewId) => `product_reviews/${reviewId}` };
export const CREATE_PRODUCT_REVIEW = { method: 'post', endpoint: 'product_reviews' };
export const GET_REVIEWS_BY_PRODUCT = { method: 'get', endpoint: (id) => `products/${id}/reviews` };
export const UPDATE_PRODUCT_REVIEW = { method: 'put', endpoint: (reviewId) => `product_reviews/${reviewId}` };
export const HIDE_SHOW_PRODUCT_REVIEW = { method: 'put', endpoint: (reviewId) => `product_reviews/${reviewId}/visibility` };
export const REPORT_PRODUCT_REVIEW = { method: 'put', endpoint: (reviewId) => `product_reviews/${reviewId}/report` };
export const GET_REVIEW_REPORTS = { method: 'get', endpoint: (reviewId) => `product_reviews/${reviewId}/reports` };
export const MARK_REVIEW_AS_HELPFUL = { method: 'put', endpoint: (reviewId) => `product_reviews/${reviewId}/helpful` };
export const GET_ALLOWED_PRODUCTS_TO_REVIEW = { method: 'get', endpoint: 'product_reviews/products' };

// Account steps
export const GET_ACCOUNT_STEPS = { method: 'get', endpoint: 'distributor/account_steps' };

// Onboarding
export const GET_ONBOARDING_FLOW = { method: 'get', endpoint: (flowCode) => `user_onboarding/flow/${flowCode}` };
export const GET_ONBOARDING_STEP = { method: 'get', endpoint: (flowCode, stepCode) => `user_onboarding/step/${flowCode}/${stepCode}` };
export const COMPLETE_ONBOARDING_STEP = { method: 'put', endpoint: (flowCode, stepCode) => `user_onboarding/complete_step/${flowCode}/${stepCode}` };
export const UPDATE_ONBOARDING_PROFILE = { method: 'put', endpoint: (stepCode) => `user_onboarding/profile/${stepCode}` };

// Custom Pricing
export const GET_CUSTOM_PRICES = { method: 'get', endpoint: 'custom_prices' };
export const SET_CUSTOM_PRICES = { method: 'post', endpoint: 'custom_prices' };

export default {};
